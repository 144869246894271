(function() {
    'use strict';
    angular
        .module("WebChat")
        .factory('channelService', channelService)
        .factory('authService', authService)
        .service('notifyService', notifyService);

    authService.$inject = ["$q", "$http"];
    channelService.$inject = ["$localStorage"];
    notifyService.$inject = ["$q", "$timeout"];


    /**
     * The authentication service.
     *
     * Methods :
     * - login
     * - checkPermission
     * Attributes :
     * - loginForm (object)
     * - loggedIn (boolean)
     * @returns {object}
     */
    function authService($q, $http) {

        return{
            login       : login,
            loginForm   : { submitted : false },
            loggedIn    : false
        };
        /**
         * This method returns a Promise of a user object, else an Error object.
         * @param username (string) The chosen username
         * @param photo (string) The selected avatar or the default
         * @param socket_id (string) The connection socket id
         * @return (object) Promise (user|Error)
         */
        function login(username, photo, socket_id) {
            var defer = $q.defer();
            var options = {
                method: "POST",
                url: "/auth.php",
                withCredentials: true,
                data: {
                    login : 1,
                    displayName: username,
                    socket_id: socket_id,
                    photo: photo
                }
            };
            $http(options)
                .then(success)
                .catch(error);

            return defer.promise;

            function error(err){
                defer.reject({reason: "The request resulted in an error.", error: err});
            }

            function success(response){
                if (response.data.success && response.data.user) {
                    defer.resolve(response.data.user);
                } else if (response.data.error) {
                    defer.reject({success: false, reason: response.data.reason ? response.data.reason : "No user", error: response.data.error});
                } else {
                    defer.reject({success: false, reason: "The request failed.", error: response.data.error});
                }
            }
        }
    }

    /**
     * Stores the channels you visit in a list
     * @param $localStorage
     * @returns (object) angular service
     */
    function channelService($localStorage) {
        var items = $localStorage.webchat.channels;
        return {
            add      : add,
            remove   : remove,
            truncate : truncate,
            list     : list
        };
        /**
         * Adds an item to the itemService
         * Returns true if successful and false if the item already exists.
         * @param item
         * @return {boolean}
         */
        function add(item) {
            if(items.indexOf(item) !== -1){
                return false;
            }
            items.push(item);
            return true;
        }

        /**
         * Removes an item from the itemService and returns tha new itemService.
         * @param item
         * @return {Array}
         */
        function remove(item) {
            var index = items.indexOf(item);
            if(index > -1){
                items.splice(index, 1);
            }
            return items;
        }

        /**
         * Returns the items stored in the service.
         * @return {Array}
         */
        function list() {
            return items;
        }

        /**
         * Deletes the saved channel list leaving only the default 'glogal'
         * @returns {Array}
         */
        function truncate() {
            $localStorage.webchat.channels = ["global"];
            return $localStorage.webchat.channels;
        }
    }

    /**
     * Notification Service.
     * It shows notifications of user login and logout on the app subheader.
     * Also, if the user has enabled notifications,
     * and the app is open in another tab, the service sends the message
     * to the underlying service worker, who in turn shows a browser notification.
     * @param $q
     * @param $timeout
     * @return {Object}
     */
    function notifyService($q, $timeout){
        var service = {
            disableNotif : false,
            message      : "",
            timeout      : null,
            checkPermission : checkPermission,
            login        : login,
            logout       : logout,
            checkAndReset: checkAndReset,
            local        : local
        };
        return service;


        /**
         * Checks if the browser is notification capable,
         * if the user has authorized the app
         * and if he currently wishes to receive notifications.
         *
         * Takes as an argument $scope.user.notif preference
         * @param state
         * @return {Promise} boolean
         */
        function checkPermission(state) {
            var defer = $q.defer();
            if (!"Notification" in window) {
                service.disableNotif = true;
                return defer.resolve(false);
            }
            state = !!state;
            Notification.requestPermission().then(function (permission) {
                switch (permission) {
                    case 'granted':
                        break;
                    case 'denied':
                        state = false;
                        break;
                    case 'default':
                        state = false;
                        break;
                }
                defer.resolve(state);
            }).catch(function (err) {
                defer.reject(err);
            });
            return defer.promise;
        }
        function login(user) {
            service.message = user + " has joined the channel.";
            checkAndReset();
        }
        function logout(user){
            service.message = user + " has left the channel.";
            checkAndReset();
        }
        function checkAndReset() {
            if (service.timeout) {
                $timeout.cancel(service.timeout);
                service.timeout = null;
            }
            service.timeout = $timeout(function () {
                service.message = "";
                service.timeout = null;
            }, 2000);
        }
        function local(message, permission) {
            if (!permission)
                return $q.reject("No permissions");
            var defer = $q.defer();
            if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                var messageChannel             = new MessageChannel();
                messageChannel.port1.onmessage = function (event) {
                    if (event.data.error) {
                        defer.reject(event.data.error);
                    } else {
                        defer.resolve(event.data);
                    }
                };
                navigator.serviceWorker.controller.postMessage(JSON.stringify(message), [messageChannel.port2]);
            } else {
                defer.reject("Not supported");
                service.disableNotif = true;
            }
            return defer.promise;
        }
    }

})();


