(function() {
    'use strict';
    angular
        .module("WebChat")
        .controller('WebChatCtrl', WebChatCtrl);

    WebChatCtrl.$inject = ['$scope', '$timeout', '$q', '$localStorage', '$mdSidenav', '$pusher', '$mdToast', 'channelService', 'authService', 'notifyService', 'smoothScrolling', '$location'];

    function WebChatCtrl($scope, $timeout, $q, $localStorage, $mdSidenav, $pusher, $mdToast, channelService, authService, notifyService, smoothScrolling, $location) {
        /**
         * Variable Initialization
         */
        var client = new Pusher('a2b9e78f2c2ecb11a390', {
            cluster: 'eu',
            encrypted: true,
            authEndpoint : "php/presence_auth.php"
        });

        var pusher = $pusher(client);

        $scope.$storage = $localStorage;
        $scope.user     = $localStorage.webchat.user;

        $scope.fabisOpen    = false;
        $scope.ajaxLoading  = true;
        $scope.chatBox      = false;
        $scope.showWebApp   = false;

        $scope.connection   = "Connecting";
        $scope.username     = "";
        $scope.message      = "";
        $scope.channel      = "";
        $scope.defaultPhoto = "images/blue-user.png";
        $scope.altPhoto     = "images/red-user.png";
        $scope.socket_id    = null;
        $scope.photos       = [
            "images/blue-user.png",
            "images/red-user.png",
            "images/orange-user.png",
            "images/yellow-user.png",
            "images/gray-user.png"
        ];
        $scope.messages     = [];
        $scope.channels     = channelService.list();

        $scope.timeFormat  = timeFormat;
        $scope.authService = authService;
        $scope.notifyService = notifyService;

        var deferredPrompt = null;

        window.addEventListener('beforeinstallprompt', function (e) {
            // console.log('beforeinstallprompt Event fired');
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            $scope.showWebApp = true;
        });
        document.getElementById("msgInput").addEventListener("focusin", function(){
	        var container = document.getElementById("messageCont");
	        var messages  = document.getElementById("messages");
	        var height    = messages.scrollHeight;
	        smoothScrolling(container, height, 500);
        }, false);
        /**
         * Installs the web app if the install prompt event was fired and was stashed.
         */
        $scope.installWebApp = function(){
            if(!deferredPrompt){
                return;
            }
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then(function(result){
                console.log('👍', 'userChoice', result);
                // Reset the deferred prompt variable, since
                // prompt() can only be called once.
                if (result.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                deferredPrompt = null;
                // Hide the install button.
                $scope.showWebApp = false;
                $scope.$applyAsync();
            });
        };

        $scope.webshare = {
            enabled: ('share' in navigator),
            shared: {
                title: "",
                text: "",
                url: ""
            },
            locationParams: {},
            consumeParam: function(param) {
                if (this.locationParams[param]) {
                    var returnParam = angular.copy(this.locationParams[param]);
                    delete this.locationParams[param];
                    return returnParam;
                }
                return undefined;
            },
            clear: function(){
                this.shared = {
                    title: "",
                    text: "",
                    url: ""
                }
            },
            check: function(){
                return this.shared.title !== "" || this.shared.text !== "";
            },
            toMessage: function(shared){
                return shared.title + " - " + shared.text + " " + shared.url;
            },
            sendShared: function(channel){
                if (!this.check()) {
                    return null;
                }
                var shared = this.toMessage(this.shared).trim();
                this.clear();
                $timeout(function () {
                    $scope.sendMessage(shared, channel);
                }, 200);
                return shared;
            },
            retrieve: function(){
                var search = $location.search();
                if (search.title) {
                    this.shared.title = search.title || "";
                    this.shared.text = search.text || "";
                    this.shared.url = search.url || "";
                }
                if (search.username) {
                    this.locationParams.username = angular.copy(search.username);
                }
                if (search.channel) {
                    this.locationParams.channel = angular.copy(search.channel);
                }
                history.pushState({}, "", window.location.origin);
            },
            share: function (e) {
                e.preventDefault();
                var shareOpts = {
                    title: 'WebChat',
                    text: 'Come chat with me!',
                    url: window.location.origin + "?channel=" + $scope.channel
                };
                if (!('share' in navigator)) {
                    gtag('event', 'share', {
                        content_type: 'link',
                        item_id: $scope.channel,
                        method: "copy"
                    });
                    console.warn("Share not supported");
                    return this.copyTextToClipboard(shareOpts.url).then(function (value) {
                        alert("Invite link copied to clipboard!");
                    }).catch(function (reason) {
                        console.warn(reason);
                    })
                }
                gtag('event', 'share', {
                    content_type: 'link',
                    item_id: $scope.channel,
                    method: "navigator"
                });
                navigator.share(shareOpts)
                .then(function (e) {
                    console.log('👍', e);
                }).catch(function (err) {
                    console.error('👎', err);
                });
            },
            fallbackCopyTextToClipboard: function (text) {
                var textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Fallback: Copying text command was ' + msg);
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }

                document.body.removeChild(textArea);
            },
            copyTextToClipboard: function (text) {
                var parent = this;
                if (!navigator.clipboard) {
                    return $q.when(true).then(function () {
                        parent.fallbackCopyTextToClipboard(text);
                    })
                }
                return navigator.clipboard.writeText(text).then(function() {
                    console.log('Async: Copying to clipboard was successful!');
                }, function(err) {
                    console.error('Async: Could not copy text: ', err);
                });
            }
        };
        $scope.webshare.retrieve();

        /**
         * Helper functions
         */

        angular.forEach(document.getElementsByClassName('blurAll'), function (item) {
            item.addEventListener("click", blurAll, false);
        });

        function blurAll() {
            angular.forEach(document.getElementsByTagName('input'), function (item) {
                if ("blur" in item) {
                    item.blur();
                }
            });
        }

        $scope.choosePhoto  = function (photo) {
            $scope.$storage.webchat.settings.lastPhoto = photo;
        };
        $scope.onSwipeRight = function () {
            $mdSidenav("left").open();
        };

        $scope.onSwipeLeft = function () {
            $mdSidenav("left").close();
        };

        $scope.toggleSideNav = function (ev) {
            ev.preventDefault();
            blurAll();
            $mdSidenav("left").toggle();
        };

        $scope.preg_replace = function (str, pattern) {
            return str.replace(pattern, "");
        };

        $scope.ucWords = function (text) {
            return ("" + text).toLowerCase().replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase();
            });
        };

        /**
         * Returns a timestamp formatted in a human readable form
         * @param time
         */
        function timeFormat(time) {
            return moment(time, "X").calendar(null, {
                sameDay : '[' + moment(time, "X").fromNow() + ']',
                nextDay : '[Tomorrow]',
                nextWeek: '[on] dddd',
                lastDay : '[Yesterday at] HH:mm',
                lastWeek: '[Last] dddd [at] HH:mm',
                sameElse: 'DD/MM/YYYY [at] HH:mm'
            });
        }

        /**
         * Shows a toast with the given text and a button to hide it
         * @param {string} text
         * @return {void}
         */
        function toast(text) {
            $mdToast
            .hide()
            .then(showToast)
            .then(hideToast);

            function showToast() {
                $mdToast.show(
                    $mdToast.simple()
                    .textContent(text)
                    .position("bottom right")
                    .action('Close')
                    .highlightAction(true)
                    .hideDelay(3000)
                );
            }

            function hideToast(response) {
                if (response === "ok") {
                    $mdToast
                    .hide();
                }
            }
        }

        /**
         * The login method
         * @param {string} username
         * @param {string=null} photo
         * @requires $scope.socket_id
         * @return Promise
         */
        $scope.login = function (username, photo) {
            if (!$scope.socket_id) {
                toast('No connection.');
                return $q.when(false);
            }
            $scope.ajaxLoading = true;
            if (!photo)
                photo = $scope.defaultPhoto;

            return $scope.authService
            .login(username, photo, $scope.socket_id)
            .then(afterLogin)
            .then(function (channel) {
                if (channel && $scope.webshare.check()) {
                    $scope.webshare.sendShared(channel);
                }
            })
            .catch(loginError);

            function afterLogin(user) {
                gtag('event', 'login', {
                    method: username
                });
                $scope.$watch('user', function () {
                    $scope.$storage.webchat.user = $scope.user;
                }, true);
                $scope.user.uid             = user.uid;
                $scope.user.displayName     = user.displayName;
                $scope.user.photo           = user.photo;
                $scope.authService.loggedIn = true;
                $scope.ajaxLoading          = false;
                $scope.chatBox              = true;
                var channel = $scope.webshare.consumeParam("channel");
	            if(channel) {
		            return joinChannel(channel);
	            }else{
                    if ($scope.user.channel && $scope.user.channel !== "") {
                        return joinChannel($scope.user.channel);
                    } else {
                        return joinChannel("global");
                    }
                }
            }

            function loginError(error) {
                console.warn(error);
                var reason = error.reason ? error.reason : "Request error!";
                toast(reason);
                $scope.ajaxLoading = false;
                return false;
            }
        };
        /**
         * Logs out the user removing him form his current subscriptions and delete any stored values.
         * @return {void}
         */
        $scope.logout = function () {
            $scope.user.uid         = "";
            $scope.user.displayName = "";
            $scope.user.photo       = "";
            $scope.user.notif       = false;
            $scope.user.channel     = "";
            unsubscribe("presence-@" + $scope.channel);
            unsubscribe("private-@" + $scope.channel);
            $scope.channel              = "";
            $scope.chatBox              = false;
            $scope.channels             = channelService.truncate();
            $scope.autoComplete.channel = "";
            $mdSidenav("left").close();
            gtag('event', 'screen_view', {
                screen_name: "login"
            });
        };

        /**
         * Pusher events
         */

        pusher.connection.bind_all(function (eventName, data) {
            switch (eventName) {
                case "initialized":
                case "connecting":
                    $scope.connection  = $scope.ucWords(eventName);
                    $scope.ajaxLoading = true;
                    break;
                case "connected":
                    $scope.connection  = $scope.ucWords(eventName);
                    $scope.socket_id   = data.socket_id;
                    $scope.ajaxLoading = false;
                    var username = $scope.webshare.consumeParam("username");
                    if ($scope.user.displayName !== "") {
                        $scope.login($scope.user.displayName, $scope.user.photo);
                    } else {
                        $scope.chatBox = false;
                        gtag('event', 'screen_view', {
                            screen_name: "login"
                        });
                        if(username){
	                        $scope.login(username);
                        }
                    }
                    break;
                case "unavailable":
                case "failed":
                case "disconnected":
                    $scope.connection  = $scope.ucWords(eventName);
                    $scope.socket_id   = null;
                    $scope.ajaxLoading = true;
                    break;
                case "message":
                    var user;
                    if (data.data && data.event === "pusher_internal:member_removed") {
                        user = atob((data.data.user_id).substring(13));
                        notifyService.logout(user);
                    }
                    if (data.data && data.event === "pusher_internal:member_added") {
                        user = data.data.user_info.displayName;
                        notifyService.login(user);
                    }

                    break;
                default:
            }
        });
        /**
         * Join a pusher channel
         * @param {string} chan - The channel to join
         */
        function joinChannel(chan) {
            var channel = chan.toLowerCase();
            channel     = channel.replace(/[^a-z0-9]/, "");
            if (channel == $scope.channel) {
                return $q.when(false);
            }
            unsubscribe("private-@" + $scope.channel);
            unsubscribe("presence-@" + $scope.channel);
            return subscribe(channel);
        }

        /**
         * Subscribes using the given channel name to:
         * - a presence channel
         * - a private channel with that name
         *
         *  Also registers listeners for users present and channel messages.
         * @param {string} channel - The channel to join
         * @return Promise
         */
        function subscribe(channel) {
            var defer = $q.defer();
            var timeout = $timeout(function () {
                defer.resolve(false);
                timeout = null;
            }, 5000);
            var presenceChannel = pusher.subscribe("presence-@" + channel);
            presenceChannel.bind('pusher:subscription_succeeded', function () {
                $mdSidenav("left").close();
                $scope.members = presenceChannel.members;
                if (timeout) {
                    $timeout.cancel(timeout);
                    timeout = null;
                }
                defer.resolve(channel);
            });
            var privateChanel = pusher.subscribe("private-@" + channel);
            privateChanel.bind('pusher:subscription_succeeded', function () {
                $scope.channel = channel;
                channelService.add(channel);
                $scope.user.channel = angular.copy($scope.channel);
                gtag('event', 'screen_view', {
                    screen_name: channel
                });
                privateChanel.unbind('client-message');
                privateChanel.bind('client-message',
                    function (newMessage) {
                        newMessage.timestamp = moment().unix();
                        addMessage(newMessage, true);
                    }
                );
            });
            return defer.promise;
        }

        /**
         * Unsubscribe from the given channel
         * @param {string} channel
         * @return {void}
         */
        function unsubscribe(channel) {
            $scope.countMembers = 0;
            $scope.messages     = [];
            if (pusher.channel(channel)) {
                pusher.unsubscribe(channel);
            }
        }

        /**
         * Sends the given message to the given channel
         * @param {string} message - The message
         * @param {string} channel - The channel to send message to
         * @param resetAfter
         * @return (void)
         */
        $scope.sendMessage = function (message, channel, resetAfter) {
            if (!channel) {
                return;
            }
            message = message || angular.copy($scope.message);
            var newMessage = {
                message  : message,
                timestamp: moment().unix(),
                sender   : $scope.user.displayName,
                uid      : $scope.user.uid,
                photo    : $scope.user.photo
            };
            if (resetAfter) {
                $scope.message = "";
            }
            if (!pusher.channel("private-@" + channel)) {
                toast("You are not in a channel.");
            }
            var triggered = pusher.channel("private-@" + channel).trigger('client-message', newMessage);
            if (triggered)
                addMessage(newMessage, false);
            else
                toast("An error has occured.");
        };

        /**
         * Adds a message to the message container,
         * then scrolls the container to show the message
         * using a smooth animation ({@link smoothScrolling})
         * @param {object} newMessage
         * @param {boolean} notify
         * @return {void}
         */
        function addMessage(newMessage, notify) {
            if (notify && $scope.user.notif) {
                notifyService
                .checkPermission($scope.user.notif)
                .then(function (permission) {
                    return notifyService.local(newMessage, permission);
                }).then(function (result) {
                    console.log(result);
                }).catch(function (err) {
                    console.warn(err);
                });
            }

            $scope.messages.push(newMessage);
            var container = document.getElementById("messageCont");
            var messages  = document.getElementById("messages");
            var height    = messages.scrollHeight;
            smoothScrolling(container, height, 500);
        }

        /**
         * The autocomplete object
         */
        $scope.autoComplete = {
            channel           : "",
            /**
             * Limits the autocomplete results depending on the input text
             * @param {string} query
             * @return (array) - Array of channels
             */
            querySearch       : function (query) {
                return query ? $scope.channels.filter(this.createFilterFor(query)) : $scope.channels;
            },
            /**
             * This event is emitted when the user chooses an item from the list.
             * Then tries to join the chosen channel ({@link joinChannel})
             * @event selectedItemChange - emitted by the autocomplete directive
             * @param item
             * @return {void}
             */
            selectedItemChange: function (item) {
                if (item) {
                    this.channel = item;
                    document.getElementById("autocomplete").getElementsByTagName("input")[0].blur();
                    joinChannel(item);
                }

            },
            /**
             * Provides a filter function to be used as a callback
             * @param {string} query
             * @return {function} callback function
             */
            createFilterFor   : function (query) {
                var lowercaseQuery = (typeof query === 'string') ? query.toLowerCase() : query;

                return function filterFn(item) {
                    return (item.indexOf(lowercaseQuery) === 0);
                };
            },
            /**
             * Adds the item to the list for autocomplete.
             * @param {string} item
             * @return {void}
             */
            addItem           : function (item) {
                if (item.length >= 4 && channelService.add(item)) {
                    joinChannel(item);
                    this.channel = item;
                }
            },
            /**
             * Checks the user input text and returns a number:
             * 0 - illegal characters used and input less than 4 chars
             * 1 - illegal characters used
             * 2 - input less than 4 chars
             * 3 - all is ok
             * @param {string} text
             * @return {number}
             */
            testText          : function (text) {
                var pattern = /^[A-Za-z0-9]*$/g;
                var res     = 0;
                if (text && pattern.test(text))
                    res++;
                if (text && text.length >= 4)
                    res += 2;
                return res;
            },
            /**
             * Removes any invalid characters from the input.
             * @return {void}
             */
            cleanStr          : function () {
                this.searchText = $scope.preg_replace(this.searchText, /[^A-Za-z0-9]/gi);
            },
            /**
             * Removes the given item from the channelService channel list
             * @param {string} item
             * @return {void}
             */
            removeItem        : function (item) {
                $scope.channels = channelService.remove(item);
            }
        };

        $scope.notificationCheck = function () {
            notifyService
            .checkPermission($scope.user.notif)
            .then(function (value) {
                if (!value) {
                    $scope.user.notif = false;
                }
            }).catch(function (error) {
                $scope.user.notif = false;
            });
        }


    }
})();