(function() {
    'use strict';
    angular
        .module("WebChat", ["ngSanitize", 'ngMessages', 'ngMaterial', 'ngStorage', 'pusher-angular', 'ngRoute'])
        .constant('smoothScrolling', smoothScrolling)
        .constant('paramEncode', paramEncode)
        .config(config);

    config.$inject = ['$httpProvider', '$mdThemingProvider', 'paramEncode', '$locationProvider'];

    function config($httpProvider, $mdThemingProvider, paramEncode, $locationProvider){
	    $locationProvider.html5Mode(true);
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        $httpProvider.defaults.transformRequest = [function(data) {
            return angular.isObject(data) && String(data) !== '[object File]' ? paramEncode(data) : data;
        }];

        $mdThemingProvider.theme('orange')
            .primaryPalette('deep-orange')
            .accentPalette('amber');

        $mdThemingProvider.theme('blue')
            .primaryPalette('blue')
            .accentPalette('orange');

        $mdThemingProvider.setDefaultTheme('orange');


    }

    angular
        .module("WebChat")
        .run(run);

    run.$inject = ['$localStorage', '$q'];

    function run($localStorage, $q){

        FastClick.attach(document.body);

        $localStorage.$default({
            webchat : {
                settings : {
                    lastPhoto : "images/blue-user.png"
                },
                user : {
                    uid             : null,
                    displayName     : "",
                    photo           : "",
                    notif           : false,
                    channel         : "global"
                },
                channels    : ["global"]
            }
        });

        window.client = new Pusher('a2b9e78f2c2ecb11a390', {
            cluster: 'eu',
            encrypted: true,
            authEndpoint : "https://webchat.gr/auth.php"
        });


        if('serviceWorker' in navigator){
            window.addEventListener("load", function() {
                navigator.serviceWorker.register('/sw.min.js').then(function(registration){
                    registration.onupdatefound = function(){
                        var installingWorker = registration.installing;
                        installingWorker.onstatechange = function(){
                            switch (installingWorker.state) {
                                case 'installed':
                                    if (navigator.serviceWorker.controller) {
                                        console.log('New or updated content is available.');
                                    } else {
                                        console.log('Content is now available offline!');
                                    }
                                    break;
                                case 'redundant':
                                    console.warn('The installing service worker became redundant.');
                                    break;
                            }
                        };
                    };
                })
                .catch(function(err){
                    console.warn(err);
                })
            })
        }
    }

    /**
     * Scrolling with animation
     * @param {Element} element
     * @param {Element} target
     * @param {int} duration
     * @return {Promise}
     */

    function smoothScrolling(element, target, duration) {
        target   = Math.round(target);
        duration = Math.round(duration);
        if (duration < 0) {
            return Promise.reject("bad duration");
        }
        if (duration === 0) {
            element.scrollTop = target;
            return Promise.resolve();
        }

        var start_time = Date.now();
        var end_time   = start_time + duration;

        var start_top = element.scrollTop;
        var distance  = target - start_top;

        // based on http://en.wikipedia.org/wiki/Smoothstep
        var smooth_step = function (start, end, point) {
            if (point <= start) {
                return 0;
            }
            if (point >= end) {
                return 1;
            }
            var x = (point - start) / (end - start); // interpolation
            return x * x * (3 - 2 * x);
        };

        return new Promise(function (resolve, reject) {
            // This is to keep track of where the element's scrollTop is
            // supposed to be, based on what we're doing
            var previous_top = element.scrollTop;

            // This is like a think function from a game loop
            var scroll_frame = function () {
                if (element.scrollTop != previous_top) {
                    reject("interrupted");
                    return;
                }

                // set the scrollTop for this frame
                var now           = Date.now();
                var point         = smooth_step(start_time, end_time, now);
                var frameTop      = Math.round(start_top + (distance * point));
                element.scrollTop = frameTop;

                // check if we're done!
                if (now >= end_time) {
                    resolve();
                    return;
                }

                // If we were supposed to scroll but didn't, then we
                // probably hit the limit, so consider it done; not
                // interrupted.
                if (element.scrollTop === previous_top
                    && element.scrollTop !== frameTop) {
                    resolve();
                    return;
                }
                previous_top = element.scrollTop;

                // schedule next frame for execution
                setTimeout(scroll_frame, 0);
            };

            // boostrap the animation process
            setTimeout(scroll_frame, 0);
        });
    }
    /**
     * The workhorse; converts an object to x-www-form-urlencoded serialization.
     * @param {Object} obj
     * @return {String}
     */

    function paramEncode(obj) {
        var query = '', name, value, fullSubName, subName, subValue, innerObj, i;

        for(name in obj) {
            value = obj[name];

            if(value instanceof Array) {
                for(i=0; i<value.length; ++i) {
                    subValue = value[i];
                    fullSubName = name + '[' + i + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += param(innerObj) + '&';
                }
            }
            else if(value instanceof Object) {
                for(subName in value) {
                    subValue = value[subName];
                    fullSubName = name + '[' + subName + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += param(innerObj) + '&';
                }
            }
            else if(value !== undefined && value !== null)
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
        }

        return query.length ? query.substr(0, query.length - 1) : query;
    }
})();
